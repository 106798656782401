<template>
  <v-snackbar
    v-model="showToast"
    :timeout="toast.timeout || 3500"
    :style="`bottom: ${index * 60}px`"
    :color="toast.type"
    left
    min-width="auto"
    transition="v-fade-transition"
  >
    {{ toast.msg }}

    <template #action="{ attrs }">
      <v-btn color="white" icon v-bind="attrs" @click="showToast = false"><v-icon>mdi-close</v-icon></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    toast: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { root: { $store } }) {
    const showToast = ref(true)
    const deleteToast = toastId => $store.commit('notifications/DELETE_TOAST', toastId)

    watch(showToast, (newVal, oldVal) => {
      if (!newVal) {
        deleteToast(props.toast.id)
      }
    })

    return {
      showToast,
    }
  },
})
</script>
